li {
  font-size: 1.2rem;
}

.bg-preview-item-badge {
  font-size: 0.9em !important;
  background-color: #e8ecee;
}

.date-picker-calendar {
  height: 100%;
  width: 100%;
  font-size: 1.2rem;
}

.date-picker-day {
  margin: 5px;
}

.list-group-item.active {
  z-index: 0 !important;
}

.preview-item {
  height: 8vh;
}

#pit-size-icon {
  padding-left: 3px;
}

#date-picker-children {
  margin: 10px 10px 10px 12px;
}
.preview-item {
  font-size: 1.3em;
  font-weight: 500;
  height: 8vh;
}

.bg-preview-item-badge {
  font-size: 0.9em !important;
  background-color: #e8ecee;
}
